import React, { useState, useEffect, Fragment  } from "react";
// import axios from "axios";

const FooterAnimation = () => {
  const [text, setText] = useState("");
  const [ip, setIp]     = useState("");
  const [showCursor, setShowCursor] = useState(true);

  const fullText = `  Appatch | ${new Date().getFullYear()} © Varexe`; // Dynamic year
  const typingSpeed = 150; // Speed in milliseconds

  // Typing Animation
  useEffect(() => {
    let index = 0;
    const typingInterval = setInterval(() => {
      if (index < fullText.length-1) {
        setText((prev) => prev + fullText[index]); // Append character by character
        index++;
      } else {
        clearInterval(typingInterval);
      }
    }, typingSpeed);

    return () => clearInterval(typingInterval);
  }, [fullText]); // Ensure the correct `fullText` is used

  // Blinking Cursor Animation
  useEffect(() => {
    const cursorInterval = setInterval(() => {
      setShowCursor((prev) => !prev); // Toggle cursor visibility
    }, 500);
  
    return () => clearInterval(cursorInterval);
  }, []);

  // Fetch IP Address
  useEffect(() => {
    // const fetchIp = async () => {
    //   try {
    //     const response = await axios.get("https://api.ipify.org?format=json");
    //     setIp(response.data.ip);
    //   } catch (error) {
    //     console.error("Failed to fetch IP address:", error);
    //   }
    // };

    // fetchIp();
  }, []);


        return(
            <Fragment>
    <div
    className="text-primary"
      style={{
        // color: "#0f0", // Green text
      }}
    >
      {/* Typing Animation */}
      <div style={{ display: "inline-block" }}>
        {text}
        {showCursor && <span style={{ color: "#0f0" }}>_</span>}
      </div>

      {/* IP Address */}
      {/* {ip && (
        <div style={{ marginTop: "5px", fontSize: "12px", color: "#aaa" }}>
          Your IP: {ip}
        </div>
      )} */}
    </div>
            </Fragment>
        )
    }



export default FooterAnimation