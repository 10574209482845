import React, { Fragment ,useContext,useState, useEffect, useRef } from 'react';
import { Link, useHistory   } from 'react-router-dom';
import store from 'store'
/*
|UI
*/
import { Divider } from 'antd';

/*
|ICONS
*/

import { PiCalendarBlankDuotone, PiDetectiveDuotone, PiArrowsOutSimpleDuotone, PiBracketsCurlyDuotone, PiLockKeyDuotone , PiListMagnifyingGlassDuotone, PiTerminalDuotone , PiTextAaDuotone, PiFileTextDuotone          } from "react-icons/pi";

/*
|PAGES
*/
// import IconImage from 'components/common/icon/IconImage';
// import VarexeLogo from 'components/common/VarexeLogo';

export const renderItems = (label ='', key = '', url= '', icon = '') => {
  return {
      label: <Link className='text-decoration-none' to={url}>{label}</Link>,
      key: key,
      icon: icon,
    }
}

export const renderGroupLabel = (label ='') => {
  return <Divider className='m-0' orientation="left" orientationMargin="0" plain>{label}</Divider>
}

// export const homeRoutes = renderItems(
//     'Appatch',
//     'appatch',
//     '/',
//     <PiCalendarBlankDuotone    className='fs-2 text-success'/>
// )


export const regexTesterRoutes = renderItems(
  'Regex Tester',
  'regex-tester',
  '/services/regex-tester',
  <PiTextAaDuotone        className='fs-2 text-primary'/>
)
export const htaccessCreatorRoutes = renderItems(
  '.htaccess Generator',
  'htaccess-generator',
  '/services/htaccess-generator',
  <PiFileTextDuotone          className='fs-2 text-primary'/>
)
export const openPortsRoutes = renderItems(
  'port checker',
  'open-ports',
  '/services/open-ports',
  <PiTerminalDuotone       className='fs-2 text-primary'/>
)
export const jsonformatterRoutes = renderItems(
  'json formatter',
  'json-formatter',
  '/services/json-formatter',
  <PiBracketsCurlyDuotone     className='fs-2 text-primary'/>
)
export const codeCompareRoutes = renderItems(
  'code compare',
  'code-compare',
  '/services/code-compare',
  <PiArrowsOutSimpleDuotone     className='fs-2 text-primary'/>
)
export const phpEncoderRoutes = renderItems(
  'php encoder',
  'php-encoder',
  '/services/php-encoder',
  <PiDetectiveDuotone     className='fs-2 text-primary'/>
)
export const passwordGeneratorRoutes = renderItems(
  'password generator',
  'password-generator',
  '/services/password-generator',
  <PiLockKeyDuotone     className='fs-2 text-primary'/>
)
export const sitemapGeneratorRoutes = renderItems(
  'sitemap generator',
  'sitemap-generator',
  '/services/sitemap-generator',
  <PiListMagnifyingGlassDuotone     className='fs-2 text-primary'/>
)




export default  function getMenuData() {

  let menuItems = [];

  // menuItems.push(homeRoutes);
  menuItems.push(regexTesterRoutes);
  menuItems.push(htaccessCreatorRoutes);
  menuItems.push(openPortsRoutes);
  menuItems.push(jsonformatterRoutes);
  menuItems.push(codeCompareRoutes);
  menuItems.push(phpEncoderRoutes);
  menuItems.push(passwordGeneratorRoutes);
  menuItems.push(sitemapGeneratorRoutes);




    return menuItems
  }