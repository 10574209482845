import React, { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';



import { Layout, Drawer, Button, theme  } from 'antd'


import {
  DesktopOutlined,
  FileOutlined,
  PieChartOutlined,
  TeamOutlined,
  UserOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined
} from '@ant-design/icons';

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'



/*
| PAGES
*/

import Menu from '../components/navbar/Menu';

import VarexeLogo from '../components/common/VarexeLogo';

// import NavbarTop from '../components/navbar/varexe/navbarTop/NavbarTop';

import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
// import Dashboard from '../components/dashboard/Dashboard';
// import DashboardAlt from '../components/dashboard-alt/DashboardAlt';
// import NavbarTop from '../components/navbar/NavbarTop';
// import NavbarVertical from '../components/navbar/varexe/menuLeft/NavbarVertical';
// import NavbarSecondaryVertical from '../components/navbar/secondary/NavbarSecondaryVertical';
import Footer from '../components/common/Footer';
// import loadable from '@loadable/component';
// import AppContext from '../context/Context';
// import ProductProvider from '../components/e-commerce/ProductProvider';
// import SidePanelModal from '../components/side-panel/SidePanelModal';
// import { toast, ToastContainer } from 'react-toastify';
// import { CloseButton, Fade } from '../components/common/Toast';
// import { getPageName } from '../helpers/utils';
// import { color } from 'echarts';
// import { Children } from 'react';

// const DashboardRoutes = loadable(() => import('./DashboardRoutes'));


const mapStateToProps = (...args) => 
  {
    // console.log('I am here');
    // console.log(args[0]) // state
    // console.log(args[1]) // ownProps
    // let args = {...args}

    return {
      context : args[0],
      props :   args[1],
    };
}



const DashboardLayout = ({context, props}) => {

  // console.log(props);
  // console.log(context);

  let isMobileView = context.settings.isMobileView;
  let leftMenuWidth = context.settings.leftMenuWidth;

  // const { isFluid, isVertical, navbarStyle } = useContext(AppContext);
  const { hash, pathname } = useLocation();
  const [collapsed, setCollapsed] = useState(false);

  const {location, children } = props; 


  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
    }, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const { Header, Sider, Content } = Layout;

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();



  const siderStyle = {
    width: leftMenuWidth,
    borderRight : 'solid 1px #FFF'
    // overflow: 'auto',
    // height: '100vh',
    // position: 'fixed',
    // insetInlineStart: 0,
    // top: 0,
    // bottom: 0,
    // scrollbarWidth: 'thin',
    // scrollbarGutter: 'stable',
    // background: colorBgContainer 
  };  

  return (
  <Layout >
    <Sider trigger={null} 
    breakpoint="lg" 
    collapsedWidth="0" 
    theme='light' 
    style={siderStyle}  
    collapsible 
    collapsed={collapsed} 
    width={leftMenuWidth}
    onCollapse={(value) => setCollapsed(value)}
    onBreakpoint={(broken) => {
    }}
      >
      <Menu />
    </Sider>    






    <Layout >
        <Header style={{ 
          padding: 0, 
          background: colorBgContainer 
          }}>
            {!isMobileView ? 
              <Button
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => setCollapsed(!collapsed)}
                style={{
                  fontSize: '16px',
                  width: 64,
                  height: 64,
                }}
              />
            : null}
            
        </Header>
        <Content style={{  margin: '16px 16px',}}>
        <div
            style={{
              minHeight: '90vh',
              // padding: 24,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >
          <div className="">{children}</div>
            </div>          
        </Content>
        <Footer style={{ textAlign: 'center' }}>
          Appatch ©{new Date().getFullYear()} Created by varexe.com
        </Footer>        
      </Layout>





  </Layout>
  );
};

export default withRouter(connect(mapStateToProps)(DashboardLayout))

