import React, { lazy, Suspense } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { connect } from 'react-redux'
import Main from './Main';
import Layout from './layouts/'

const routes = [
  {
    path: '/',
    Component: lazy(() => import('./components/landing/components/Landing')),
    exact: true,
    },
    {
      path: '/services/',
      Component: lazy(() => import('./components/landing/components/Landing')),
      exact: true,
      },    
  {
    path: '/services/json-formatter',
    Component: lazy(() => import('./components/formatter/components/JsonFormatter')),
    exact: true,
    },
  {
    path: '/services/php-encoder',
    Component: lazy(() => import('./components/phpEncoder/components/PhpEncoder')),
    exact: true,
    },
  {
    path: '/services/sitemap-generator',
    Component: lazy(() => import('./components/sitemap/components/Sitemap')),
    exact: true,
    },
  {
    path: '/services/password-generator',
    Component: lazy(() => import('./components/passwordGenerator/components/PasswordGenerator')),
    exact: true,
    },
  {
    path: '/services/code-compare',
    Component: lazy(() => import('./components/codeCompare/components/CodeCompare')),
    exact: true,
    },
  {
    path: '/services/open-ports',
    Component: lazy(() => import('./components/openPorts/components/openPorts')),
    exact: true,
    },
  {
    path: '/services/regex-tester',
    Component: lazy(() => import('./components/RegexTester/components/RegexTester')),
    exact: true,
    },
  {
    path: '/services/htaccess-generator',
    Component: lazy(() => import('./components/htaccessCreator/components/HtaccessCreator')),
    exact: true,
    },
  {
    path: '/contact',
    Component: lazy(() => import('./components/contact/components/Contact')),
    exact: true,
    },


    {
    path: '/design',
    Component: lazy(() => import('./components/design')),
    exact: true,
    },
]

const mapStateToProps = ({ settings }) => ({
  routerAnimation: settings.routerAnimation,
})

const Router = ({ history, routerAnimation }) => {    
  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={state => {
            const { location } = state
            return (
                <Switch location={location}>
                {routes.map(({ path, Component, exact }) => 
                {
                return(
                  <Route
                    path={path}
                    key={path}
                    exact={exact}
                    render={({ 
                        location, 
                        match 
                    }) => {
                      return (
                        <div  className={routerAnimation}>
                            <Suspense fallback={null}>
                                <Component match={match} />
                            </Suspense>
                        </div>
                      )
                    }}
                  />
                )
                }
                )}
                <Redirect to="/errors/404" />
              </Switch>
            )
          }}
        />
      </Layout>
    </ConnectedRouter>
  )
}

export default connect(mapStateToProps)(Router)
