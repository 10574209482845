import React, { Component, Fragment } from 'react';
import { Link, useHistory   } from 'react-router-dom';

import logo from '../../assets/img/logo/appatch-logo.png'
import Flex from '../common/Flex'

export default class VarexeLogo extends Component {

    static defaultProps = {
        type : 'svg'
    }

    constructor(props){

        super(props);
        this.state = {
            type : this.props.type

        }

    }


    componentDidMount(){

    }

    getImage = () => {


    }

    render(){
        return(
            <Fragment>
                 <div>
                 <Link className='text-decoration-none' to={'/'}>                 
                    <Flex direction='row' className="text-primary">  
                        <div className="">
                            <img src={logo} width={60} />
                        </div>
                        <div className='ms-2 mt-2' >
                            <p className="fs-2 mb-0 mt-1 fw-bold " style={{lineHeight: 0.6}}>APPATCH</p>
                            <p>Developer tools</p>
                        </div>
                    </Flex>                
                 </Link>
                 </div>
            </Fragment>
        )
    }

}