import React, { useContext, useEffect } from 'react';
import { withRouter, useLocation } from 'react-router-dom';



import { Layout, Drawer } from 'antd'
import { connect } from 'react-redux'



/*
| PAGES
*/


import Menu from '../components/navbar/Menu';
import Footer from '../components/common/Footer';
import Header from '../components/common/Header';

import PropTypes from 'prop-types';


import { getPageName } from '../helpers/utils';



const mapStateToProps = ({ settings }) => ({
  isContentMaxWidth:      settings.isContentMaxWidth,
  isAppMaxWidth:          settings.isAppMaxWidth,
  isGrayBackground:       settings.isGrayBackground,
  isSquaredBorders:        settings.isSquaredBorders,
  isCardShadow: settings.isCardShadow,
  isBorderless: settings.isBorderless,
  isTopbarFixed: settings.isTopbarFixed,
  isGrayTopbar: settings.isGrayTopbar,
})

const LandingLayout = props => {
  // const { isFluid, isVertical, navbarStyle } = useContext(AppContext);
  const { hash, pathname } = useLocation();

  const {location, children } = props; 




  useEffect(() => {
    
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
    }, 0);
  }, []);

  useEffect(() => {

    window.scrollTo(0, 0);
  }, [pathname]);


  return (
        <div className='content'>
          <Layout
            style={{
              background: 'none',
            }}
          >
            {/* <NavbarVertical /> */}
            {/* <Menu /> */}

            <Layout 
            style={{background: 'none'}}
            >
              <Layout.Header
              className='p-0'
                style={{
                  textAlign: 'center',
                  background: 'none',
                }}              
              >
                <Header />
              </Layout.Header>
                <Layout.Content 
                style={{height: '100%', position: 'relative', background: 'none', 
                // padding: '0 10px' 
              }}
              className=''
                >
                  <div className="varexe_components">{children}</div>
                </Layout.Content> 
                <Layout.Footer
                  className='p-0'
                    style={{
                      textAlign: 'center',
                      background: 'none',
                    }}              
                  >
                <Footer />
              </Layout.Footer>                           
              </Layout>
          </Layout>
        </div>
  );
};

export default withRouter(connect(mapStateToProps)(LandingLayout))

