import React, { Fragment ,useContext,useState, useEffect, useRef } from 'react';
import classNames                 from 'classnames';
import { Link, useHistory   } from 'react-router-dom';

import PropTypes                  from 'prop-types';
import { connect } from 'react-redux'
import store from 'store'
import { useLocation } from 'react-router';
import { map } from 'lodash';

/*
| UI
*/
import { Menu, Layout, Skeleton, Tooltip, Button, Dropdown, Space, Typography    } from 'antd';
import { Nav, Navbar, Row, Col, ButtonGroup, OverlayTrigger } from 'react-bootstrap';


/*
| Icons
*/

import { PiMathOperationsDuotone, PiPlusMinusDuotone, PiUserPlusDuotone, PiReceiptDuotone } from "react-icons/pi"; 
/*
| IMAGES
*/
// import Logo from '../../Logo';
// import VarexeLogo from 'components/common/VarexeLogo';

/*
| PAGES
*/
// import { navbarBreakPoint, topNavbarBreakpoint } from '../../../../config';
import AppContext                 from '../../../context/Context';

import Flex                       from '../../common/Flex'
// import ToggleThemDark             from '../../partials/ToggleThemDark'

import VarexeLogo from '../../common/VarexeLogo';
import getMenuData from '../../../services/menu'

// import UserRoleService from '../../../user-roles/services/index'
import { all, put, call } from 'redux-saga/effects'
const mapStateToProps = ({ menu, settings, user }) => ({
  // menuData: menu.menuData,
  isMenuCollapsed: settings.isMenuCollapsed,
  isMobileView: settings.isMobileView,
  isMenuUnfixed: settings.isMenuUnfixed,
  isMenuShadow: settings.isMenuShadow,
  leftMenuWidth: settings.leftMenuWidth,
  menuColor: settings.menuColor,
  logo: settings.logo,
  role: user.role,
})

const NavbarLeft = ({
  dispatch,
  // menuData = [],
  // location: { pathname },

  isMenuCollapsed,
  isMobileView,
  isMenuUnfixed,
  isMenuShadow,
  leftMenuWidth,
  menuColor,
  logo,
  role,
}) => {

  let menuData = getMenuData();

  const [selectedKeys, setSelectedKeys] = useState(store.get('app.menu.selectedKeys') || [])
  const [openedKeys, setOpenedKeys] = useState(store.get('app.menu.openedKeys') || [])

  const [isUserRoleLoading, setIsUserRole] = useState(true);


  const { pathname } = useLocation();
  useEffect(() => {    
  }, [pathname, menuData])



  // const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  // const history = useHistory();

  let time = null;


  const onCollapse = (value, type) => {
    if (type === 'responsive' && isMenuCollapsed) {
      return
    }
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isMenuCollapsed',
        value: !isMenuCollapsed,
      },
    })
    setOpenedKeys([])
  }

  const onOpenChange = keys => {
    store.set('app.menu.openedKeys', keys)
    setOpenedKeys(keys)
  }

  const handleClick = e => {
    store.set('app.menu.selectedKeys', [e.key])
    setSelectedKeys([e.key])
  }

  return (
<Fragment>


            <div style={{
              background:'rgba(0,0,0,0.1)', 
              // widows: '70%',
              height: '40px',
              margin: '12px',
              borderRadius: '6px'
              }}>
                <VarexeLogo />
            </div>

            <Menu
                onClick={handleClick}
                selectedKeys={selectedKeys}
                openKeys={openedKeys}
                onOpenChange={onOpenChange}
                inlineCollapsed={false}
                mode="inline"
                // mode="vertical"
                // className={'navigation'}
                // inlineIndent="15"
                items={menuData}
                style={{
                  marginTop: 50 ,
                  background: 'none', 
                  border: 'none',
                  //  minWidth: 0, flex: "auto"
                  // minHeight : '100vh'
                }}
              >
            </Menu>  






</Fragment>
  );
};

NavbarLeft.propTypes = {
  label: PropTypes.string
};

export default connect(mapStateToProps)(NavbarLeft)

